import React, { useEffect } from 'react';
import { graphql } from "gatsby"

import BlockZone from "~blockZone"
import { PageMeta } from "../components/pageMeta"

const ClientPage = ({ data: { page } }) => {


    const { seo, pageBlocks } = page || {}

    return (
        <div>
            <PageMeta {...seo} />
            {pageBlocks && <BlockZone {...pageBlocks} />}
        </div>
    )
}

export default ClientPage;

export const pageQuery = graphql`
  query blogPostQuery($slug: String!) {
    page: sanityClientPost(slug: { current: { eq: $slug } }) {
    seo {
        ...MetaCardFields
    }
    
    pageBlocks {
        ...BlockZone
      }
    }
  }
`;